.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    /* width: 80%; */
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .modal-main2 {
    padding: 30px;
    position:fixed;
    background: white;
    /* width: 80%; */
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .modal-main3 {
    padding: 30px;
    position:fixed;
    background: white;
    width: 50%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .content img {
    position: absolute;
    top: 3px;
    right: 6px;
    cursor: pointer;
  }

  .click img {
    position: absolute;
    opacity: 0;
    background-color: aliceblue;
    top: 448px;
    right: 19px;
    cursor: pointer;
  }